import React, { useState, useMemo, useEffect, useContext } from "react";
import "./meeting.css";
import {
  AzureCommunicationTokenCredential,
  CommunicationUserIdentifier,
} from "@azure/communication-common";
import {
  CallAdapter,
  CallComposite,
  createAzureCommunicationCallAdapter,
  CallCompositeOptions,
  COMPOSITE_LOCALE_FR_FR,
  CallAdapterState,
} from "@azure/communication-react";
import { useNavigate } from "react-router-dom";
import { MeetingContext } from "../App";
import { useMsal } from "@azure/msal-react";
import { getLoginRequest } from "../auth/authConfig";
import { getMeetingToken } from "../helpers/MeetingToken";
import { MeetingToken } from "../models/meetingToken";
import { sendEvent } from "../events/events";
import { EventIds } from "../models/eventIds";

const isTeamsMeetingLink = (link: string): boolean =>
  link.startsWith("https://teams.microsoft.com/l/meetup-join");

export function Meeting() {
  const [adapter, setAdapter] = useState<CallAdapter>();
  const [meetingToken, setMeetingToken] = useState<MeetingToken>();
  const [user, setUser] = useState<CommunicationUserIdentifier>();
  const { instance, accounts } = useMsal();

  const nextMeeting = useContext(MeetingContext);
  const [displayName, setDisplayName] = useState<string>("Borne");

  const options: CallCompositeOptions = {
    callControls: {
      devicesButton: false,
      participantsButton: false,
      displayType: "default",
      cameraButton: true,
      microphoneButton: true,
      screenShareButton: false,
      endCallButton: true,
    },
  };

  const credential = useMemo(() => {
    try {
      if (meetingToken) {
        setUser({ communicationUserId: meetingToken.userId });
        return new AzureCommunicationTokenCredential(meetingToken.token);
      } else {
        console.log("Ask ACS Credentials");
        const request = {
          ...getLoginRequest(),
          account: accounts[0],
        };
        instance.acquireTokenSilent(request).then((result) => {
          // store displayName
          if (result.account) {
            setDisplayName(result.account.name as string);
          }
          getMeetingToken(result.accessToken).then(
            (token: MeetingToken | null) => {
              if (token) {
                console.log("Meeting Token Received", token);
                setMeetingToken(token);
              }
            }
          );
        });
      }
    } catch {
      console.error("Failed to construct token credential");
      return undefined;
    }
  }, [meetingToken]);

  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!!credential && nextMeeting && displayName) {
        const callLocator = isTeamsMeetingLink(
          nextMeeting.appointment!.onlineMeetingUrl
        )
          ? { meetingLink: nextMeeting.appointment!.onlineMeetingUrl }
          : { groupId: nextMeeting.appointment!.onlineMeetingUrl };
        const createAdapter = async (
          credential: AzureCommunicationTokenCredential
        ): Promise<void> => {
          sendEvent(
            nextMeeting.token,
            EventIds.kioskConnectedToMeeting,
            nextMeeting.appointment!.appointmentId
          );
          nextMeeting.changeMeetingStatus(true);
          setAdapter(
            await createAzureCommunicationCallAdapter({
              userId: user as CommunicationUserIdentifier,
              displayName: displayName,
              credential,
              locator: callLocator,
            })
          );
        };
        createAdapter(credential);
      }
    })();
  }, [credential, meetingToken]);

  useEffect(() => {
    adapter?.onStateChange((state: CallAdapterState) => {
      switch (state.call?.state) {
        case "Connected":
          break;
        case "InLobby":
          // sendEvent(
          //   nextMeeting.token,
          //   EventIds.kioskInLobby,
          //   JSON.stringify(nextMeeting.appointment!)
          // );
          break;
        case "Disconnecting":
          navigate("/");
          break;
      }
    });
    return () => {
      (async () => {
        if (!adapter) {
          return;
        }

        nextMeeting.changeMeetingStatus(false);
        sendEvent(
          nextMeeting.token,
          EventIds.kioskDisconnectedFromMeeting,
          nextMeeting.appointment!.appointmentId
        );
        await adapter.leaveCall().catch((e) => {
          console.error("Failed to leave call", e);
        });
        adapter.dispose();
        navigate("/");
      })();
    };
  }, [adapter]);

  if (adapter) {
    return (
      <div className="meetingUi">
        <CallComposite
          adapter={adapter}
          options={options}
          locale={COMPOSITE_LOCALE_FR_FR}
        />
      </div>
    );
  }

  if (credential === undefined) {
    /*return (
      <div className="meetingUi">
        Failed to construct credential. Provided token is malformed.
      </div>
    );*/
    console.log("Failed to construct credential. Provided token is malformed");
  }

  return <div className="lds-dual-ring"></div>;
}
