import { Configuration } from "@azure/msal-browser"

// const msalConfig: Configuration = {
//     auth: {
//         clientId: "889b1288-0d0c-4a0f-8329-a37359ff5f06",
//         authority: "https://login.microsoftonline.com/common", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
//         redirectUri: "http://localhost:3000",
//     },
//     cache: {
//         cacheLocation: "sessionStorage", // This configures where your cache will be stored
//         storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
//     }
// }

// const apiConfig = {
//     resourceUri: "https://localhost:7045",
//     resourceScopes: ["api://6338b477-71f6-4858-9639-a6d87a0c2313/.default"]
// }

// const loginRequest = {
//     scopes: [...apiConfig.resourceScopes]
// }

export function getAuthConfig(): Configuration{
    return {
        auth:{
            clientId: `${process.env.REACT_APP_CLIENT_ID}`,
            authority: `${process.env.REACT_APP_AUTHORITY}`,
            redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`
        },
        cache: {
            cacheLocation: "sessionStorage", // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        }
    }
}

export function getApiConfig(){
    return {
        resourceUri: `${process.env.REACT_APP_RESOURCE_URI}`,
        resourceScopes: [`${process.env.REACT_APP_RESOURCE_SCOPE}`]
    };
}

export function getLoginRequest(){
    return {
        scopes: [...getApiConfig().resourceScopes]
    };
}