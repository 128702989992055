import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Launcher from "./launcher/launcher";
import { Meeting } from "./meeting/meetings";
import { Favorite } from "./favorite/favorite";
import { Configuration } from "./config/config";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { getAuthConfig } from "./auth/authConfig";
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { TeamsAppConfig } from "./teamsApp/config/config";
import { SidePanel } from "./teamsApp/meeting/sidePanel";
import { AdminApp } from "./adminApp/App";
import { Provider, teamsTheme } from "@fluentui/react-northstar";

const msalInstance = new PublicClientApplication(getAuthConfig());

ReactDOM.render(
  <React.StrictMode>
    <Provider theme={teamsTheme}>
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<Launcher />} />
              <Route path="/partners"></Route>
              <Route path="/meeting" element={<Meeting />}></Route>
              <Route path="/internet" element={<Favorite />}></Route>
              <Route path="/config" element={<Configuration />}></Route>
            </Route>
            <Route path="/teams">
              <Route path="/teams/config" element={<TeamsAppConfig />} />
              <Route path="/teams/sidePanel" element={<SidePanel />} />
            </Route>
            <Route path="/admin" element={<AdminApp />}></Route>
          </Routes>
        </BrowserRouter>
      </MsalProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
