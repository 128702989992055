import { SiteCard } from "./siteCard/siteCard";
import "./favorite.css";

export function Favorite() {
  return (
    <>
      <SiteCard
        title={"CPAM"}
        url={"https://www.ameli.fr"}
        logo={"./cpam.png"}
      ></SiteCard>
      <SiteCard
        title={"CAF"}
        url={"https://www.caf.fr"}
        logo={"./caf.png"}
      ></SiteCard>
      <SiteCard
        title={"Agence de Recouvrement"}
        url={"https://www.pension-alimentaire.caf.fr/"}
        logo={"./aripa.png"}
      ></SiteCard>
      <SiteCard
        title={"Conseil Régional"}
        url={"https://www.normandie.fr/"}
        logo={"./cr-normandie.png"}
      ></SiteCard>
      <SiteCard
        title={"Conseil Départemental"}
        logo={"./mancheSite.png"}
        url={"https://www.manche.fr/"}
      ></SiteCard>
      <SiteCard
        title={"MSA"}
        logo={"./msa.png"}
        url={"https://cotesnormandes.msa.fr/lfp"}
      ></SiteCard>
      <SiteCard
        title={"Assurance Retraite"}
        logo={"./assurance-retraite.png"}
        url={"https://www.pension-alimentaire.caf.fr/"}
      ></SiteCard>
      <SiteCard
        title={"CARSAT"}
        logo={"./carsat.png"}
        url={"https://www.carsat-normandie.fr/"}
      ></SiteCard>
      <SiteCard
        title={"Info Santé Normandie"}
        logo={"./infoSante.png"}
        url={"http://www.info-sante-normandie.fr/"}
      ></SiteCard>
      <SiteCard
        title={"monenfant.fr"}
        logo={"./mon-enfant.png"}
        url={"https://monenfant.fr/"}
      ></SiteCard>
    </>
  );
}
