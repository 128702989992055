import * as msTeams from "@microsoft/teams-js";
import { useEffect } from "react";

export function TeamsAppConfig() {
  useEffect(() => {
    msTeams.initialize(() => {
      console.log("MS Teams Init");

      msTeams.appInitialization.notifySuccess();
      msTeams.settings.setValidityState(true);
    });

    msTeams.settings.registerOnSaveHandler((saveEvent) => {
      msTeams.settings.setSettings(
        {
          entityId: "visioRdvMeetingAgent",
          contentUrl: `${window.location.origin}/teams/sidepanel`,
          suggestedDisplayName: "Visio Accueil - Agent",
        },
        (status, reason) => {
          console.log("Failed", status, reason);
        }
      );
      saveEvent.notifySuccess();
    });
  }, []);
  return <div>Votre compte est maintenant configuré pour être utilisé</div>;
}
