import { getApiConfig } from "../auth/authConfig";
import { MeetingToken } from "../models/meetingToken";

export async function getMeetingToken(accessToken: string): Promise<MeetingToken | null>{
    return new Promise((resolve, reject) => {
        fetch(`${getApiConfig().resourceUri}/meeting/token`, {
            method: "POST",
            headers: {
            Authorization: `Bearer ${accessToken}`,
            },
        }).then((result: Response) => {
          result.json().then((token: MeetingToken) => {
            resolve(token);
          });
        }).catch((err) => {
            console.log(err);
            reject(err);
        });
    });
}