import { getApiConfig } from "../../auth/authConfig";

export function uploadFile(accessToken: string, formData: FormData): Promise<void>{
    return new Promise((resolve, reject) => {
        fetch(`${getApiConfig().resourceUri}/meeting/upload`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            body: formData
        }).then(() => {
            resolve();
        }).catch((err) => {
            console.log(err);
            reject(err);
        });
    });
}