import { EventNotification } from "../models/eventNotification";
import { Appointment } from "../models/appointment";
import { getApiConfig } from "../auth/authConfig";

export async function processEventNotification(accessToken: string, notification: EventNotification, currentAppointments: Appointment[]): Promise<Appointment[]> {
    
    switch(notification.eventType){
        case "Created":
            console.log("Appointment created", notification.resource, currentAppointments);
            const appointment = await getEvent(accessToken,notification.resource);
            currentAppointments.push(appointment);
            break;
        case "Deleted":            
            console.log("Appointment deleted", notification.resource, currentAppointments);
            const eventId = notification.resource.split("/")[3];
            currentAppointments = currentAppointments.filter(a => a.appointmentId !== eventId);
            break;
        default:
            break;
    }

    return new Promise((resolve, reject) => resolve(currentAppointments));
}

function getEvent(accessToken: string, resource: string): Promise<Appointment>{ 

    return new Promise((resolve, reject) => {
        fetch(`${getApiConfig().resourceUri}/event?resource=${resource}`,{
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }).then((resp: Response) => {
                resp.json().then((appointment: Appointment) => {
                    resolve(appointment);
                });
            }, err => console.log(err));
    });
}