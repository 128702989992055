import { AdaptiveCard, Action, OpenUrlAction } from "adaptivecards";
import { useEffect, useRef, useContext } from "react";
import { MeetingContext } from "../../App";
import { sendEvent } from "../../events/events";
import { EventIds } from "../../models/eventIds";
import "./siteCard.css";
import * as ACData from "adaptivecards-templating";

export function SiteCard(props: { title: string; url: string; logo: string }) {
  const cardWrapper = useRef<HTMLDivElement>(null);
  const context = useContext(MeetingContext);

  const cardTemplate = {
    type: "AdaptiveCard",
    $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
    version: "1.5",
    body: [
      {
        type: "TextBlock",
        text: "${title}",
        wrap: true,
        fontType: "Default",
        size: "Large",
        weight: "Bolder",
      },
      {
        type: "Image",
        horizontalAlignment: "Center",
        url: "${logo}",
        selectAction: {
          type: "Action.OpenUrl",
          url: "${url}",
        },
      },
    ],
  };

  useEffect(() => {
    if (!cardWrapper) return;

    const template = new ACData.Template(cardTemplate);

    const cardPayload = template.expand({
      $root: {
        title: props.title,
        logo: props.logo,
        url: props.url,
      },
    });

    const adaptiveCard = new AdaptiveCard();
    adaptiveCard.parse(cardPayload);

    adaptiveCard.onExecuteAction = (action: Action) => {
      if (action instanceof OpenUrlAction) {
        //Send Event
        sendEvent(context.token, EventIds.navigateToWebsite, props.url);

        window.open(action.url, "_blank")!.focus();
      }
    };

    cardWrapper.current!.innerHTML = "";
    adaptiveCard.render(cardWrapper.current as HTMLDivElement);
  }, [cardWrapper]);

  return <div className="adaptiveCard" ref={cardWrapper} />;
}
