import React, { useContext, useEffect } from "react";
import { LauncherButton } from "./launcherButton";
import { MeetingContext } from "../App";
import { MeetingInfo } from "../models/meetingInfo";

export default function Launcher() {
  const nextMeeting = useContext<MeetingInfo>(MeetingContext);

  useEffect(() => {
    console.log("Updated on launcher", nextMeeting);
  }, [nextMeeting]);

  return (
    <>
      <LauncherButton
        url={"/meeting"}
        disabled={nextMeeting.appointment === undefined}
      >
        {nextMeeting.appointment && (
          <div>
            <p className="infoText">Le prochain rendez - vous démarre le :</p>

            <h1 className="hour">
              {new Date(nextMeeting?.appointment!.startTime).toLocaleDateString(
                "fr-FR",
                {
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )}
            </h1>
          </div>
        )}
        {!nextMeeting.appointment && <span> 📅 Aucun rendez-vous</span>}
      </LauncherButton>
      <LauncherButton url={"/internet"} disabled={false}>
        🌍 Internet
      </LauncherButton>
    </>
  );
}
