import { getApiConfig } from "../auth/authConfig";

export function subscribeToCalendarEvents(accessToken: string): Promise<string>{
    return new Promise((resolve, reject) => {
        fetch(`${getApiConfig().resourceUri}/appointments/subscribe`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        }).then((resp : Response) => {
            if(resp.status === 200){
                resp.text().then((value) => {            
                    resolve(value);
                });
            }
        }, err => reject(err));
    });
}