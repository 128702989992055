import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import {
  MdOutlineKeyboardBackspace,
  MdCheckCircle,
  MdHighlightOff,
} from "react-icons/md";

import "./App.css";
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";
import { useNavigate, useLocation, Link, Outlet } from "react-router-dom";
import { getApiConfig, getLoginRequest } from "./auth/authConfig";

import { AuthenticationResult } from "@azure/msal-browser";
import { Appointment } from "./models/appointment";
import moment from "moment";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";
import { subscribeToCalendarEvents } from "./meeting/meetingHelper";
import { EventNotification } from "./models/eventNotification";
import { processEventNotification } from "./notif/notificationHandler";
import { useIdleTimer } from "react-idle-timer";
import { uploadFile } from "./teamsApp/meeting/meetingCommand";
import { IVisioRdvApi } from "./desktop/ivisioRdvApi";
import { getPrinter, getScan } from "./helpers/config";
import { MeetingInfo } from "./models/meetingInfo";
import { version } from "../package.json";
import { EventIds } from "./models/eventIds";
import { sendEvent } from "./events/events";

export const MeetingContext = React.createContext<MeetingInfo>({
  appointment: undefined,
  changeMeetingStatus: () => {},
  token: "",
});

declare global {
  interface Window {
    visioRdvApi: IVisioRdvApi;
  }
}

function App() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const navigate = useNavigate();
  const [kioskHubConnection, setKioskHubConnection] = useState<HubConnection>();
  const location = useLocation();
  const [hubState, setHubState] = useState<HubConnectionState>(
    HubConnectionState.Disconnected
  );
  const [meetingInProgress, setMeetingInProgress] = useState<boolean>(false);
  const [token, setToken] = useState<string>();
  const [reconnectTimeoutId, setReconnectTimeoutId] = useState<any>(undefined);
  const [nextAppointment, setNextAppointment] = useState<
    Appointment | undefined
  >(undefined);

  const appointmentsRef = useRef<Appointment[]>();
  const meetingIsInProgressRef = useRef<boolean>(false);

  const [desktopAppVersion, setDesktopAppVersion] = useState<string>("");

  const handleOnIdle = () => {
    console.log("user is idle");
    //navigate("/");
  };

  const handleOnActive = () => {
    console.log("user is active");
  };

  const request = {
    ...getLoginRequest(),
    account: accounts[0],
  };

  //Idle Time
  const idleHandler = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
  });

  // get next appointment from list
  function getNextAppointment(appointmentsList: Appointment[]) {
    console.log("Get Next Appointment");
    if (appointmentsList && appointmentsList.length > 0) {
      const appointment = appointmentsList!
        .sort((a, b) => (moment(a.endTime).isBefore(b.endTime) ? -1 : 1))
        .filter(
          (appointment) =>
            moment(appointment.endTime).subtract(5, "minutes") > moment()
        )[0];
      if (
        appointment &&
        appointment.appointmentId !== nextAppointment?.appointmentId
      ) {
        setNextAppointment(appointment);
      } else {
        setNextAppointment(undefined);
      }
    } else {
      setNextAppointment(undefined);
    }
  }

  useEffect(() => {
    appointmentsRef.current = appointments;
    getNextAppointment(appointments!);
  }, [appointments]);

  useEffect(() => {
    meetingIsInProgressRef.current = meetingInProgress;
  }, [meetingInProgress]);

  // trigger Sign In
  function signIn() {
    instance
      .loginRedirect(getLoginRequest())
      .then(() => {
        console.log("Login Success");
      })
      .catch((e) => {
        console.error(e);
      });
  }

  function subscribeToHubEvents() {
    kioskHubConnection?.onclose((err) => {
      setHubState(HubConnectionState.Disconnected);
      reconnectToHub();
    });

    kioskHubConnection?.onreconnected((connectionId) => {
      setHubState(HubConnectionState.Connected);
    });

    kioskHubConnection?.onreconnecting((err) => {
      setHubState(HubConnectionState.Reconnecting);
    });

    kioskHubConnection?.on(
      "SendEventNotification",
      (eventNotif: EventNotification) => {
        console.log("Event Received", eventNotif);
        if (isAuthenticated) {
          processEventNotification(token!, eventNotif, appointments!).then(
            (newAppointments) => {
              console.log("New list of appointments", newAppointments);
              setAppointments(newAppointments);
            }
          );
        }
      }
    );

    kioskHubConnection?.on("Print", (fileUri: string) => {
      if (meetingIsInProgressRef.current) {
        sendEvent(token!, EventIds.print);
        const printerName = getPrinter();

        if (printerName === undefined) {
          console.log("Printer not configured");
          return;
        }

        console.log("Print", fileUri, printerName);
        window.visioRdvApi.print(fileUri, printerName!);
      } else {
        console.warn("Print canceled, meeting is not started");
      }
    });

    kioskHubConnection?.on("Scan", (partnerConnectionId: string) => {
      if (meetingIsInProgressRef.current) {
        const scannerName = getScan();

        sendEvent(token!, EventIds.scan);

        if (scannerName === undefined) {
          console.log("Scanner not defined");
          return;
        }

        console.log("Scan", scannerName);
        window.visioRdvApi.scan(scannerName!).then((scan: string) => {
          if (isAuthenticated) {
            const request = {
              ...getLoginRequest(),
              account: accounts[0],
            };
            instance
              .acquireTokenSilent(request)
              .then((result: AuthenticationResult) => {
                const formData = new FormData();
                formData.append("file", scan);
                formData.append("type", "scan");
                formData.append("partnerConnectionId", partnerConnectionId);

                uploadFile(result.accessToken, formData).then(() => {});
              });
          }
        });
      } else {
        console.warn("Scan canceled, meeting is not started");
      }
    });
  }

  function initHubConnection(accessToken: string) {
    const connection = new HubConnectionBuilder()
      .withUrl(
        `${
          getApiConfig().resourceUri
        }/hub?type=kiosk&&desktopVersion=${desktopAppVersion}&&webVersion=${version}`,
        {
          accessTokenFactory: () => accessToken,
        }
      )
      .withAutomaticReconnect()
      .build();

    setKioskHubConnection(connection);
  }

  useEffect(() => {
    kioskHubConnection?.start().then(
      () => {
        if (reconnectTimeoutId !== undefined) {
          console.log("Clear reconnect timeout");
          clearTimeout(reconnectTimeoutId);
        }

        subscribeToHubEvents();
        setHubState(HubConnectionState.Connected);
        console.log("Connection ID", kioskHubConnection.connectionId);
        console.log("Connected to Kiosk Hub");
      },
      (err) => {
        console.error("Failed to connect to hub", err);
        reconnectToHub();
      }
    );
  }, [kioskHubConnection]);

  function reconnectToHub() {
    const timeoutId = setTimeout(() => {
      setKioskHubConnection(undefined);
      instance
        .acquireTokenSilent(request)
        .then(
          (result: AuthenticationResult) => {
            setToken(result.accessToken);
            initHubConnection(result.accessToken);
          },
          (err) => console.log(err)
        )
        .catch((error) => {
          console.log("failed to authenticate", error);
        });
      console.log("Trying to reconnect to hub", token);
    }, 30000);
    setReconnectTimeoutId(timeoutId);
  }

  // use effect used for authentication
  useEffect(() => {
    if (isAuthenticated) {
      instance
        .acquireTokenSilent(request)
        .then(
          (result: AuthenticationResult) => {
            setToken(result.accessToken);
            subscribeToCalendarEvents(result.accessToken).then(
              (subId: string) => {
                console.log("Subscribed to appointements events", subId);
                initHubConnection(result.accessToken);
              },
              (err) => console.log(err)
            );
          },
          (err) => console.log(err)
        )
        .catch((error) => {
          console.log("failed to authenticate", error);
        });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      signIn();
    }
    if (window.visioRdvApi) {
      window.visioRdvApi.getVersion().then((appVersion: string) => {
        setDesktopAppVersion(appVersion);
      });
    }
    setInterval(() => {
      getNextAppointment(appointmentsRef.current!);
    }, 60000);
  }, []);

  useEffect(() => {
    if (hubState === HubConnectionState.Connected) {
      getMeetings();
    }
  }, [hubState]);

  function getMeetings() {
    fetch(`${getApiConfig().resourceUri}/appointments`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resp: Response) => {
        if (resp.status === 200) {
          resp.json().then((value: Appointment[]) => {
            console.log("Next Meeting Received", value);
            setAppointments(value);
          });
        } else if (resp.status === 204) {
          console.warn("No meeting planned");
          // setAppointments([]);
        }
      })
      .catch((err) => console.log("Fetch error", err));
  }

  // redirect to config when user press ctrl + alt + s
  function handleKeyPress(e: KeyboardEvent<HTMLDivElement>) {
    if (e.ctrlKey && e.altKey && e.key === "s") {
      navigate("/config");
    }
  }

  return (
    <MeetingContext.Provider
      value={{
        appointment: nextAppointment,
        changeMeetingStatus: (meetingIsInProgress: boolean) => {
          console.log("Meeting is in progress changed", meetingIsInProgress);
          setMeetingInProgress(meetingIsInProgress);
        },
        token: token!,
      }}
    >
      <AuthenticatedTemplate>
        <div className="App" tabIndex={0} onKeyUp={handleKeyPress}>
          <header className="header">
            {location.pathname !== "/" && (
              <div className="backButton">
                <Link to={"/"}>
                  <MdOutlineKeyboardBackspace color="#444" size={70} />
                </Link>
              </div>
            )}
          </header>
          <main className="main">
            <Outlet />
          </main>
          <footer className="footer"></footer>

          <div className="status">
            {hubState === HubConnectionState.Connected && (
              <MdCheckCircle color="#1BB818" size={32} />
            )}

            {hubState !== HubConnectionState.Connected && (
              <MdHighlightOff color="#DE2D23" size={32} />
            )}
          </div>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="App">
          <header className="header"></header>
          <main className="main"></main>
          <footer className="footer"></footer>
        </div>
      </UnauthenticatedTemplate>
    </MeetingContext.Provider>
  );
}

export default App;
