import * as msTeams from "@microsoft/teams-js";
import "./sidePanel.css";
import { useEffect, useRef, useState } from "react";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";
import { getApiConfig } from "../../auth/authConfig";
import { uploadFile } from "./meetingCommand";
import { Button, FilesEmptyIcon, SaveIcon } from "@fluentui/react-northstar";
import { MdCheckCircle, MdHighlightOff } from "react-icons/md";

export function SidePanel() {
  const [hubConnection, setHubConnection] = useState<HubConnection>();
  const [chatId, setChatId] = useState<string>();
  const [accessToken, setAccessToken] = useState<string>("");
  const [scannedFiles, setScannedFiles] = useState<string>("");
  const [fileInputValue, setFileInputValue] = useState<string>("");
  const [hubState, setHubState] = useState<HubConnectionState>(
    HubConnectionState.Disconnected
  );

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    msTeams.initialize(() => {
      var authTokenRequest = {
        successCallback: (result: any) => {
          console.log("Token success", result);
          setAccessToken(result);
          connectToHub(result);
        },
        failureCallback: (error: any) => {
          console.log("Failure: " + error);
        },
      };
      msTeams.getContext((context: msTeams.Context) => {
        setChatId(context.chatId);
      });
      msTeams.authentication.getAuthToken(authTokenRequest);
    });
  }, []);

  function connectToHub(token: string) {
    const connection = new HubConnectionBuilder()
      .withUrl(`${getApiConfig().resourceUri}/hub?type=partner`, {
        accessTokenFactory: () => token,
      })
      .withAutomaticReconnect()
      .build();
    setHubConnection(connection);
  }

  useEffect(() => {
    subscribeToHubEvents();

    hubConnection?.onreconnecting((error) => {
      setHubState(HubConnectionState.Reconnecting);
    });

    hubConnection?.onreconnected((connectionId) => {
      setHubState(HubConnectionState.Connected);
    });

    hubConnection?.onclose((err) => {
      setHubState(HubConnectionState.Disconnected);
    });

    hubConnection?.start().then(
      () => {
        console.log("Connection ID", hubConnection.connectionId);
        console.log("Connected to Visio RDV Hub");
        setHubState(hubConnection.state);
      },
      (err) => console.log(err)
    );
  }, [hubConnection]);

  function subscribeToHubEvents() {
    hubConnection?.on("NotifyFile", (fileUri) => {
      console.log("File Received");
      setScannedFiles(fileUri);
    });
  }

  function scan() {
    if (hubConnection?.state === HubConnectionState.Connected) {
      hubConnection?.invoke("scan", chatId, hubConnection.connectionId);
    }
  }

  function handlePrintClick(event: any) {
    if (hiddenFileInput) {
      hiddenFileInput.current?.click();
    }
  }

  function print(files: FileList | null) {
    if (files?.length === 0) {
      return;
    }
    // Get Files
    const fileToUpload: File = files?.item(0) as File;
    const formData = new FormData();
    formData.append("file", fileToUpload, fileToUpload?.name);
    formData.append("chatId", chatId as string);
    formData.append("type", "print");

    uploadFile(accessToken, formData).then(() => {
      setFileInputValue("");
    });
  }

  return (
    <div className="container">
      {hubState === HubConnectionState.Connected && (
        <div>
          <MdCheckCircle color="#1BB818" size={16} /> Connecté
        </div>
      )}

      {hubState !== HubConnectionState.Connected && (
        <div>
          <MdHighlightOff color="#DE2D23" size={16} /> Déconnecté
        </div>
      )}

      <div className="commandButton">
        <Button
          fluid
          content="Imprimer"
          icon={<FilesEmptyIcon />}
          iconPosition="before"
          onClick={handlePrintClick}
          disabled={hubState !== HubConnectionState.Connected}
        />
        <input
          type="file"
          onChange={(e) => print(e.target.files)}
          accept="application/pdf"
          style={{ display: "none" }}
          ref={hiddenFileInput}
          value={fileInputValue}
        />
      </div>
      <div className="commandButton">
        <Button
          fluid
          content="Scanner"
          icon={<SaveIcon />}
          iconPosition="before"
          onClick={scan}
          disabled={hubState !== HubConnectionState.Connected}
        />
      </div>
      <div>
        {scannedFiles !== "" && (
          <a href={scannedFiles}>
            Cliquez ici pour télécharger le fichier scanné
          </a>
        )}
      </div>
    </div>
  );
}
