import { useMsal } from "@azure/msal-react";
import {
  Button,
  FormInput,
  Divider,
  Grid,
  Label,
  FormButton,
  Form,
  FormDropdown,
  DropdownProps,
} from "@fluentui/react-northstar";
import { useEffect, useState } from "react";
import { IVisioRdvApi } from "../desktop/ivisioRdvApi";
import { getPrinter, getScan, setPrinter, setScan } from "../helpers/config";
import "./config.css";
import { version } from "../../package.json";

declare global {
  interface Window {
    visioRdvApi: IVisioRdvApi;
  }
}

export function Configuration() {
  const { instance, accounts } = useMsal();
  const [printers, setPrinters] = useState<string[]>([]);
  const [scanners, setScanners] = useState<string[]>([]);
  const [selectedPrinter, setSelectedPrinter] = useState<string>();
  const [selectedScan, setSelectedScan] = useState<string>();
  const [desktopAppVersion, setDesktopAppVersion] = useState<string>();

  useEffect(() => {
    if (window.visioRdvApi) {
      window.visioRdvApi.listScan().then((scanList: string[]) => {
        setScanners(scanList.filter((v) => v !== ""));
        console.log(scanList.filter((v) => v !== ""));
      });

      window.visioRdvApi.listPrinters().then((printersList: string[]) => {
        setPrinters(printersList);
        console.log(printersList);
      });

      if (getScan()) {
        setSelectedScan(getScan() as string);
      }

      if (getPrinter()) {
        setSelectedPrinter(getPrinter() as string);
      }

      window.visioRdvApi.getVersion().then((appVersion: string) => {
        setDesktopAppVersion(appVersion);
      });
    }
  }, []);

  useEffect(() => {
    setPrinter(selectedPrinter as string);
  }, [selectedPrinter]);

  useEffect(() => {
    setScan(selectedScan as string);
  }, [selectedScan]);

  function logout() {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }

  function handlePrinterChanged(event: any, data: DropdownProps) {
    setSelectedPrinter(data.value?.toString());
  }

  function handleScanChanged(event: any, data: DropdownProps) {
    setSelectedScan(data.value?.toString());
  }

  const items = [
    <div key="account">
      <span className="labelTable">Compte Connecté: </span>
      {accounts[0].name}
    </div>,
    <div key="tenant">
      <span className="labelTable">Tenant ID: </span>
      {accounts[0].tenantId}
    </div>,
    <div key="webappVersion">
      <span className="labelTable">Version Application Web: </span>
      {version}
    </div>,
    <div key="desktopappVersion">
      <span className="labelTable">Version Application Desktop: </span>
      {desktopAppVersion}
    </div>,
  ];

  const labelIdPrinter = "printer-label";
  const labelIdScan = "scan-label";

  return (
    <div className="configContainer">
      <Divider color="grey" content="Informations" fitted className="divider" />
      <Grid rows="2" columns="2" content={items} />
      <Button content="Se déconnecter" onClick={logout} />
      <Divider
        color="grey"
        content="Configuration"
        fitted
        className="divider"
      />
      <Form className="configForm">
        <FormDropdown
          label={{
            content: `Imprimante`,
            id: labelIdPrinter,
          }}
          inline
          items={printers}
          aria-labelledby={labelIdPrinter}
          value={selectedPrinter}
          onChange={handlePrinterChanged}
        />
        <FormDropdown
          label={{
            content: `Scanner`,
            id: labelIdScan,
          }}
          inline
          items={scanners}
          aria-labelledby={labelIdScan}
          value={selectedScan}
          onChange={handleScanChanged}
        />
      </Form>
    </div>
  );
}
