export function setPrinter(printerName: string){
    localStorage.setItem("printer", printerName);
}

export function setScan(scanName: string){
    localStorage.setItem("scan", scanName);
}

export function getPrinter(){
    const printerName = localStorage.getItem("printer");

    if(printerName === "undefined"){
        return undefined;
    }

    return printerName;
}

export function getScan(){
    const scannerName = localStorage.getItem("scan");

    if(scannerName === "undefined"){
        return undefined;
    }

    return scannerName;
}