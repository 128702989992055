import React from "react";
import { useNavigate } from "react-router-dom";
import "./launcherButton.css";

export function LauncherButton(props: {
  image?: string;
  url: string;
  disabled: boolean;
  children?: React.ReactNode;
}) {
  const navigate = useNavigate();
  function start() {
    navigate(props.url);
  }

  return (
    <button
      className="roundedButton"
      style={{ backgroundImage: `url(${props.image})` }}
      onClick={start}
      disabled={props.disabled}
    >
      <span>{props.children}</span>
    </button>
  );
}
