import { getApiConfig } from "../auth/authConfig";
import { EventIds } from "../models/eventIds";

export function sendEvent(accessToken: string, eventId: EventIds, data: string = ""): Promise<void>{
    return new Promise((resolve, reject) => {
        fetch(`${getApiConfig().resourceUri}/event`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                eventId: eventId,
                data: data
            })
        }).then((resp : Response) => {
            if(resp.status === 200){
                resolve();
            }
        }, err => reject(err));
    });
}